// extracted by mini-css-extract-plugin
export var bottomExperience = "Need-module--bottomExperience--4248f";
export var btn_white2_border_banner_web_App_need_btn = "Need-module--btn_white2_border_banner_web_App_need_btn--bb51b";
export var customWebDes = "Need-module--customWebDes--dee83";
export var descriptionWeb = "Need-module--descriptionWeb--78306";
export var experienceData = "Need-module--experienceData--d65e1";
export var experienceDetails = "Need-module--experienceDetails--602e3";
export var experienceTitle = "Need-module--experienceTitle--b1fa5";
export var headingWeb = "Need-module--headingWeb--f543f";
export var needBtnWeb = "Need-module--needBtnWeb--36c4e";
export var needContainer = "Need-module--needContainer--d998c";
export var needContent = "Need-module--needContent--23ad7";